<template>
  <el-dialog
    width="800px"
    title="新建返佣计划"
    :visible="dialogVisible"
    @close="closeDialog"
  >
    <el-form
      label-position="top"
      :model="viewResultForm"
      class="ruleForm"
      :rules="viewResultFormRules"
      ref="ruleForm"
    >
      <el-form-item label="返佣计划名称" style="width:100%" prop="name">
        <el-input v-model="viewResultForm.name"></el-input>
      </el-form-item>
      <el-form-item label="备注" style="width:100%" prop="remark">
        <el-input type="textarea" v-model="viewResultForm.remark"></el-input>
      </el-form-item>
      <div v-for="(item, index) in list" :key="index" class="itemForm">
        <Divider :title="item.target"></Divider>
        <el-form-item label="结算单位" prop="unit">
          <el-select
            v-model="item.cycleUtil"
            placeholder="请选择结算单位"
            style="width: 100%"
            @change="unitChange(item)"
          >
            <el-option
              v-for="it in item.unitOptions"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算周期" prop="cycle">
          <el-select
            v-model="item.cycleNumber"
            placeholder="请选择结算周期"
            style="width: 100%"
          >
            <el-option
              v-for="it in item.cycleOptions"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="width:100%">
          <div
            style="    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;"
          >
            结算细则
          </div>
          <!-- <div style="color:grey">
            结算时间规则：0-当前周/月 1-下个周/月 2-下下个周/月 依次类推
          </div> -->
          <div>
            <el-table :data="item.list" style="width: 100%">
              <el-table-column
                type="index"
                label="结算次数"
                width="100"
              ></el-table-column>

              <el-table-column prop="date" label="结算时间规则">
                <template slot-scope="scope">
                  <el-input-number
                    @change="timeChange(scope.$index, item.list)"
                    :min="
                      scope.$index > 0
                        ? item.list[scope.$index - 1].timeNumber + 1
                        : item.list[scope.$index].timeNumber
                    "
                    v-model="scope.row.timeNumber"
                    type="number"
                    style="width:100%"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="结算比例（%）">
                <template slot-scope="scope">
                  <el-input-number
                    style="width:100%"
                    v-model="scope.row.range"
                    type="number"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    @click="deleteItem(item, scope.row)"
                    :disabled="item.list.length === 1"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-button
          style="width:100%;margin-top:20px"
          icon="el-icon-plus"
          plain
          @click="addItem(item)"
          >添加规则</el-button
        >
      </div>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import * as _ from 'lodash';
const weekOptions = [
  {
    label: '每周星期一',
    value: 1
  },
  {
    label: '每周星期二',
    value: 2
  },
  {
    label: '每周星期三',
    value: 3
  },
  {
    label: '每周星期四',
    value: 4
  },
  {
    label: '每周星期五',
    value: 5
  }
];
const monthOptions = [];
const unitOptions = [
  {
    label: '月',
    value: '月'
  },
  {
    label: '周',
    value: '周'
  }
];
for (let index = 1; index < 29; index++) {
  monthOptions.push({
    label: `每月${index}日`,
    value: index
  });
}
import { api } from '/src/api/base';
//新增接口
export const RecruitReturnPlanAdd = api('/recruitReturnPlan')(
  'recruitReturnPlan.add.json'
);
export default {
  components: {
    Divider: () => import('/src/components/divider.vue')
  },
  data() {
    return {
      viewResultForm: {
        name: '',
        remark: ''
      },
      viewResultFormRules: {
        name: [
          { required: true, message: '请输入返返佣计划名称', trigger: 'blur' }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      cycleOptions: weekOptions,
      list: [
        {
          target: '平台',
          cycleUtil: '月',
          cycleNumber: 1,
          unitOptions: unitOptions,
          cycleOptions: monthOptions,
          list: [
            {
              timeNumber: 1,
              range: 30
            }
          ]
        },
        {
          target: '应聘者',
          cycleUtil: '月',
          cycleNumber: 1,
          unitOptions: unitOptions,
          cycleOptions: monthOptions,
          list: [
            {
              timeNumber: 1,
              range: 30
            }
          ]
        },
        {
          target: '推荐人',
          cycleUtil: '月',
          cycleNumber: 5,
          unitOptions: unitOptions,
          cycleOptions: monthOptions,
          list: [
            {
              timeNumber: 1,
              range: 30
            }
          ]
        }
      ]
    };
  },
  props: {
    dialogVisible: Boolean
  },
  created() {},
  methods: {
    unitChange(item) {
      if (item.cycleUtil === '月') {
        item.cycleOptions = monthOptions;
      }
      if (item.cycleUtil === '周') {
        item.cycleOptions = weekOptions;
      }
    },
    addItem(item) {
      item.list.push({
        timeNumber: item.list[item.list.length - 1].timeNumber + 1,
        range: 30
      });
    },
    deleteItem(item, row) {
      item.list = item.list.filter(it => it != row);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const list = _.flatten(this.list.map(it => it.list));
          const total = _.sumBy(list, 'range');
          if (total !== 100) {
            return this.$message.error(
              `结算总比例必须等于100%！目前结算总比例为${total}%`
            );
          }
          const _list = this.list.map(it => {
            return {
              target: it.target,
              cycleUtil: it.cycleUtil,
              cycleNumber: it.cycleNumber,
              list: it.list
            };
          });
          const params = {
            ...this.viewResultForm,
            content: JSON.stringify(_list)
          };
          await RecruitReturnPlanAdd(params);
          this.closeDialog();
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.$emit('close', this.dialogVisible);
    },
    timeChange(index, itemList) {
      this.list = this.list.map(it => {
        if (it.list === itemList) {
          return {
            ...it,
            list: it.list.map((_it, _index) => {
              return {
                ..._it,
                timeNumber: _index > index ? _it.timeNumber + 1 : _it.timeNumber
              };
            })
          };
        } else {
          return {
            ...it
          };
        }
      });

      // if (
      //   _target.list[index].timeNumber === _target.list[index - 1].timeNumber
      // ) {
      //   _target.list[index].timeNumber += 1;
      //   console.log(_target, 2);
      //   return this.$message.error("结算时间不能和上次结算时间相同");
      // }

      // console.log(item.timeNumber, list[list.length - index].timeNumber);
      // if (item.timeNumber === list[list.length - index].timeNumber) {
      //   console.log(item, index, list);
      //   list[index].timeNumber += 1;
      //   return this.$message.error("结算时间不能");
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.itemForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
